
import { useState, useEffect } from "react";

const TarifsTable = () => {
    const [tarifs, setTarifs] = useState([]);

    const [searchInput, setSearchInput] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    useEffect(() => {
            fetch('/api/tarifs') 
            .then((response) => response.json())
            .then((data) => {
                setTarifs(data);
                setLoading(false);
            })
            .catch((err) => {
                setError('Failed to load students');
                setLoading(false);
            });

            gsap.from('.table-tarifs-search #searchCityInput', {
                scrollTrigger: {
                    trigger: '.table-tarifs-search #searchCityInput',
                    start: 'top 70%'
                },
                duration: 1.5,
                opacity: 0,
                scale:0.9,
                y: 4,
                x:-80,
                ease: 'back'
            });

            gsap.from('.table-tarifs', {
                scrollTrigger: {
                    trigger: '.table-tarifs',
                    start: 'top 70%'
                },
                duration: 1.5,
                opacity: 0,
                scale:0.9,
                y: 60,
                x:-80,
                ease: 'back'
            });

            gsap.from('.table-tarifs-pagination > .btn', {
                scrollTrigger: {
                    trigger: '.table-tarifs-pagination > .btn',
                    start: 'top 70%'
                },
                duration: 1.5,
                opacity: 0,
                scale:0.9,
                y: 0,
                x: 60,
                stragger: 0.25,
                ease: 'back'
            });
    }, []);

    const filteredTarifs = tarifs.filter(tarif => {
        return tarif.zone.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(searchInput.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""))
    });
    
    // Calculate the indices for the current page
    const indexOfLastTarif = currentPage * itemsPerPage;
    const indexOfFirstTarif = indexOfLastTarif - itemsPerPage;
    const currentTarifs = filteredTarifs.slice(indexOfFirstTarif, indexOfLastTarif);

    // Calculate total pages
    const totalPages = Math.ceil(filteredTarifs.length / itemsPerPage);

    // Handle page navigation
    const handleNextPage = () => {
        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };

    const handleSearch = (e) => {
        setSearchInput(e.target.value);
        setCurrentPage(1);
    }

    const displayTarifs = () => {

        return currentTarifs.map((tarif, index) => {
            
            return <tr key={index}>
                <td>{ tarif.zone }</td>
                <td>{ `${tarif.tarif} Dhs` }</td>
                <td>{ `${tarif.tarif_retour} Dhs` }</td>
                <td>{ `${tarif.delai_livraison} Heures`  }</td>
            </tr>;
        });
    }
    return (
        <>
         <div className="table-tarifs-head">
                <div className="table-tarifs-search">
                    <input className="form-control" id="searchCityInput" placeholder="Recherche..." onChange={handleSearch}/>
                </div>
                <div className="table-tarifs-pagination">
                    <button aria-label="Prev Btn" className={`btn ${currentPage == 1 ? 'disabled' : ''}`} onClick={handlePreviousPage}><i className="fa-solid fa-chevron-left"></i></button>
                    <button aria-label="Next Btn" className={`btn ${currentPage >= totalPages ? 'disabled' : ''}`} onClick={handleNextPage}><i className="fa-solid fa-chevron-right"></i></button>
                </div>
            </div>
            <table className="table dt-responsive table-tarifs">
                <thead className="thead_dark">
                    <tr>
                        <th className="th_text">Ville</th>
                        <th className="th_text">Livré</th>
                        <th className="th_text">Retour</th>
                        <th className="th_text">Délai</th>
                    </tr>
                </thead>
                <tbody>
                    { displayTarifs() }
                </tbody>
            </table>
            { currentTarifs.length === 0
             && <div style={{ textAlign:'center', padding:'10px'}}>Aucun résultat correspond</div>}
            { currentTarifs.length > 0 &&
            <div className="table-tarifs-footer">{filteredTarifs.length} villes | {currentPage} / {totalPages} pages</div>}
        </>
    )
}

export default TarifsTable;