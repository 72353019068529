import { useState, useEffect} from 'react';
import Select from 'react-select'
import axios from 'axios';

const Step1 = ({data, onInputChange, onSelectChange, errors}) => {

    const [citiesOptions, setCitiesOptions] = useState({});

    useEffect(() => {

        const fetchOptions = async () => {
            try {
                const response = await axios.get('/api/zones');
                const formattedOptions = response.data.map(option => ({
                    value: option.id, 
                    label: option.zone
                }));

                setCitiesOptions(formattedOptions);
            } catch (error) {
                console.error("Error fetching options:", error);
            }
        };

        fetchOptions();
    }, []);

    return (
        <div className="auth-step1">
            <div className="row g-4">
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="form-label" htmlFor="">Prénom <span className="lbl-obligatoire">*</span></label>
                        <div className="form-control-wrap">
                            <input type="text" className={`form-control ${ errors?.prenom ? 'error': ''}`} name="prenom" value={data.prenom} onChange={e => onInputChange(e)} placeholder="Prénom" />
                            {errors?.prenom && <small className="error">{errors?.prenom}</small>}
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="form-label" htmlFor="">Nom <span className="lbl-obligatoire">*</span></label>
                        <div className="form-control-wrap">
                            <input type="text" className={`form-control ${ errors?.nom ? 'error': ''}`} name="nom" value={data.nom} onChange={e => onInputChange(e)} placeholder="Nom" />
                            {errors?.nom && <small className="error">{errors?.nom}</small>}
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="form-label" htmlFor="">Ville <span className="lbl-obligatoire">*</span></label>
                        <Select name="ville" 
                            options={citiesOptions} 
                            onChange={(option) => onSelectChange(option, 'ville')}
                            value={data.ville}
                            placeholder="-Sélectionner-"
                        />
                        {errors?.ville && <small className="error">{errors?.ville}</small>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="form-label" htmlFor="">Téléphone <span className="lbl-obligatoire">*</span></label>
                        <div className="form-control-wrap">
                            <input type="tel" className={`form-control ${ errors?.telephone ? 'error': ''}`} name="telephone" onChange={e => onInputChange(e)} placeholder="06-XX-XX-XX-XX" value={data.telephone} />
                            {errors?.telephone && <small className="error">{errors?.telephone}</small>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Step1;