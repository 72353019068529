import { useState } from "react";
import { useNavigate } from "react-router-dom";
import './SignUp.css';
import axios from 'axios';
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import CheckIcon from '@mui/icons-material/Check';
import Snackbar from '@mui/material/Snackbar';

const SignUp = () => {
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(1);
    const [formData, setFormData] = useState({
        prenom: "",
        nom: "",
        zone: "",
        telephone: "",
        marque: "",
        business_url: "",
        ville: null,
        forme_juridique: null,
        orders_per_day: null,
        email: "",
        password: ""
    });
    const [errors, setErrors] = useState({});
    const [submiting, setSubmiting] = useState(false);

    const handleInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setFormData((prev) => ({...prev, [name]: value}));
        setErrors((prev) => ({
            ...prev,
            [name]: "",
        }));
    }

    const handleSelectChange = (selectedOption, name) => {
        setFormData((prev) => ({
            ...prev,
            [name]: selectedOption,
        }));
        setErrors((prev) => ({
            ...prev,
            [name]: "",
        }));
    };

    const validateTelephone = (telephone) => {
        const regex = /^(06|07)(-\d{2}){4}$|^212(06|6|07|7)\d{8}$|^(06|07)(\d{2}){4}$/;
        return regex.test(telephone);
    };

    const validateEmail = (email) => {
        const regex = /^[\S|-|_]+@\s+\.\s+$/;
        return regex.test(email);
    };

    const validateFields = () => {
        const newErrors = {};
        if (currentStep === 1) {

            if(formData.prenom === '')
            {
                newErrors.prenom = "Prénom est obligatoire.";
            }

            if(formData.nom === '')
            {
                newErrors.nom = "Nom est obligatoire.";
            }

            if(formData.telephone === '')
            {
                newErrors.telephone = "Téléphone est obligatoire.";
            }

            else if(!validateTelephone(formData.telephone))
            {
                newErrors.telephone = "Téléphone est invalide.";
            }

            if(!formData.ville)
            {
                newErrors.ville = "Ville est obligatoire.";
            }
        }
        if (currentStep === 2) {

            if(formData.marque === '')
            {
                newErrors.marque = "Marque est obligatoire.";
            }

            if(formData.business_url === '')
            {
                newErrors.business_url = "Store est obligatoire.";
            }

            if(!formData.forme_juridique)
            {
                newErrors.forme_juridique = "Forme est obligatoire.";
            }

            if(!formData.orders_per_day)
            {
                newErrors.orders_per_day = "Nombre de commandes est obligatoire.";
            }
        }
        if (currentStep === 3) {

            if(formData.email === '')
            {
                newErrors.email = "Email est obligatoire.";
            }
            else if(validateEmail(formData.email)){
                newErrors.email = "Email est invalide.";
            }

            if(formData.password === '')
            {
                newErrors.password = "Mot de passe est obligatoire.";
            }
            else if(formData.password.length < 8) {
                newErrors.password = "Le mot de passe doit avoir au moins 8 caractères."
            }
        }
       
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }

    const nextStep = () => {
        if (currentStep < 3 && validateFields()) setCurrentStep(currentStep + 1);
    };

    const prevStep = () => {
        if (currentStep > 1){
            setCurrentStep(currentStep - 1);
            setErrors({});
        } 
    };

    const showSuccessMsg = () => {
        <Snackbar
            message="I love snacks"
            autoHideDuration={1200}
        />
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(currentStep === 3 && validateFields())
        {
            setSubmiting(true);

            const sendData = async () => {
                try {
                    const response = await axios.post('/api/new-account', formData, {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        withCredentials: true
                    })
                    .then(response => {
                        showSuccessMsg();
                        navigate(`/confirmation/${formData.email}`);
                    });

                } catch (error) {
                    console.log(error);
                    if (error.response && error.response.data.errors) {
                        setErrors(error.response.data.errors);
                    }
                    setSubmiting(false);
                } finally {
                    
                }
            };

            sendData();
        }
    }

    return (
        <div class="nk-content" style={{backgroundColor: '#fff'}}>
            <div class="">
                <div class="nk-split-content nk-auth-container vh w-100">
                    <div className="nk-block nk-block-middle nk-auth-body">
                        <div className="nk-block-head">
                            <div className="nk-block-head-content">
                                <h5 className="nk-block-title" style={{textAlign: 'center'}}>Création de nouveau compte</h5>
                            </div>
                        </div>
                        <div id="registerSteps">
                            <form onSubmit={handleSubmit}>
                                <div className="auth-signup-steps">
                                    <div className="pagination-header">
                                        <div className={`auth-step ${
                                                    currentStep > 1 
                                                    ? "completed" 
                                                    : currentStep === 1
                                                    ? 'active' : ''
                                                }`}>
                                            <div className="auth-circle">
                                            {
                                                    currentStep > 1 
                                                    ? <CheckIcon />
                                                    : '1'
                                            }
                                            </div>
                                            <p>Informations personnels</p>
                                        </div>
                                        <div className={`line ${currentStep > 1 ? 'completed' : ''}`}></div>
                                        <div className={`auth-step ${
                                                    currentStep > 2 
                                                    ? "completed" 
                                                    : currentStep === 2
                                                    ? 'active' : ''
                                                }`}>
                                            <div className="auth-circle">
                                            {
                                                    currentStep > 2 
                                                    ? <CheckIcon />
                                                    : '2'
                                            }
                                            </div>
                                            <p>Boutique online</p>
                                        </div>
                                        <div className={`line ${currentStep > 2 ? 'completed' : ''}`}></div>
                                        <div className={`auth-step ${
                                                    currentStep > 3 
                                                    ? "completed" 
                                                    : currentStep === 3
                                                    ? 'active' : ''
                                                }`}>
                                            <div className="auth-circle">
                                            {
                                                    currentStep > 3 
                                                    ? <CheckIcon />
                                                    : '3'
                                            }
                                            </div>
                                            <p>Authentification</p>
                                        </div>
                                    </div>
                                    { currentStep == 1 
                                        && <Step1 data={formData} errors={errors} onInputChange={handleInputChange} onSelectChange={handleSelectChange} />}
                                        { currentStep == 2 
                                        && <Step2 data={formData} errors={errors} onInputChange={handleInputChange} onSelectChange={handleSelectChange} />}
                                        { currentStep == 3 
                                        && <Step3 data={formData} errors={errors} onInputChange={handleInputChange} submiting={submiting}/>}
                                </div>
                                <div className="d-flex justify-end my-4">
                                {(currentStep > 1 && !submiting) &&
                                <button className="btn btn-lg rounded-32px" type="button" onClick={prevStep}>Précédent</button>}

                                {
                                submiting === true ? <button className="btn btn-lg btn-secondary rounded-32px w-100 justify-center" style={{backgroundColor:'#a5a5a5', borderColor:'#a5a5a5', cursor: 'progress'}} type="button">
                                    <span>Chargement<span className="loading-dots"></span></span>
                                    </button>
                                : currentStep < 3 ?
                                    <button className="btn btn-lg btn-success rounded-32px" type="button" onClick={nextStep}>Suivant</button>
                                    :<button className="btn btn-lg btn-success rounded-32px" type="submit" key="submit-btn">Terminer</button>}
                                </div>
                            </form>
                        </div>
                        <div className="form-note-s2 pt-4">J'ai déjà un compte <a className="link-orange" href="/login">S'identifier</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUp;