
const Step3 = ({data, onInputChange, errors, submiting}) => {
    return (
        <div className="auth-step3">
            <div className="row g-4">
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="form-label" htmlFor="">Email <span className="lbl-obligatoire">*</span></label>
                        <div className="form-control-wrap">
                            <input type="email" disabled={submiting} className={`form-control ${ errors?.email ? 'error': ''}`} name="email" placeholder="Email" value={data.email} onChange={e => onInputChange(e)}  />
                            {errors?.email && <small className="error">{typeof errors.email === 'string' ? errors.email : errors.email[0]}</small>}
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="form-label" htmlFor="">Mot de passe <span className="lbl-obligatoire">*</span></label>
                        <input type="password" disabled={submiting} className={`form-control ${ errors?.password ? 'error': ''}`} name="password" placeholder="Mot de passe" value={data.password} onChange={e => onInputChange(e)} />
                        {errors?.password && <small className="error">{errors?.password}</small>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Step3;