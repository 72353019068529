
import { useState, useEffect} from 'react';
import Select from 'react-select'

const Step2 = ({data, onInputChange, onSelectChange, errors}) => {
    const formsJuridiques = ['Individual', 'Auto Entrepreneur', 'SARL AU', 'SARL', 'Coopérative', 'Autre'];
    const ordersPerDay = ['Entre 1 et 5', 'Entre 6 et 10', 'Entre 11 et 20', 'Entre 21 et 30', 'Entre 31 et 50', 'Plus de 50'];
    const [forms, setForms] = useState({});
    const [orders, setOrders] = useState({});

    useEffect(() => {
        const formsOptions = formsJuridiques.map(option => ({
            value: option, 
            label: option
        }));

        setForms(formsOptions);

        const ordersOptions = ordersPerDay.map(option => ({
            value: option, 
            label: option
        }));

        setOrders(ordersOptions);
    }, []);

    return (
        <div className="auth-step2">
            <div className="row g-4">
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="form-label" htmlFor="">Nom de la marque <span className="lbl-obligatoire">*</span></label>
                        <input type="text" className={`form-control ${ errors?.marque ? 'error': ''}`} name="marque" value={data.marque} onChange={e => onInputChange(e)} placeholder="Nom de votre marque" />
                        {errors?.marque && <small className="error">{errors?.marque}</small>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="form-label" htmlFor="">Votre site ou page Facebook/Instagram
                        <span className="lbl-obligatoire">*</span></label>
                        <input type="text" className={`form-control ${ errors?.business_url ? 'error': ''}`} name="business_url" value={data.business_url} onChange={e => onInputChange(e)} placeholder="Url du page / store" />              
                        {errors?.business_url && <small className="error">{errors?.business_url}</small>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="form-label" htmlFor="">Forme juridique 
                        <span className="lbl-obligatoire">*</span></label>
                        <Select name="forme_juridique" 
                            options={forms} 
                            onChange={(option) => onSelectChange(option, 'forme_juridique')}
                            value={data.forme_juridique}
                            placeholder="-Sélectionner-"
                        />
                        {errors?.forme_juridique && <small className="error">{errors?.forme_juridique}</small>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="form-label" htmlFor="">Nombre de commandes par jour 
                        <span className="lbl-obligatoire">*</span></label>
                        <Select name="orders_per_day" 
                            options={orders} 
                            onChange={(option) => onSelectChange(option, 'orders_per_day')}
                            value={data.orders_per_day}
                            placeholder="-Sélectionner-"
                        />
                        {errors?.orders_per_day && <small className="error">{errors?.orders_per_day}</small>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Step2;