import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import TarifsTable from "./components/TarifsTable";
import SignUp from "./components/SignUp";
import VerifyEmail from "./components/VerifyEmail";

if(document.getElementById('tarifsTable'))
{
    const root = ReactDOM.createRoot(document.getElementById('tarifsTable'));
    root.render(
        <TarifsTable />
    );
}

if(document.getElementById('authComponent'))
{
    const root = ReactDOM.createRoot(document.getElementById('authComponent'));
    root.render(
        <Router>
            <Routes>
                <Route path="/nouveau-compte" element={<SignUp />} />
                <Route path="/confirmation/:email" element={<VerifyEmail />} />
            </Routes>
        </Router>
    );
}
